import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
// @ts-ignore
import { RenderAfterNavermapsLoaded } from 'react-naver-maps'
import { ENV } from './ENV'

ReactDOM.render(
    <React.StrictMode>
        <RenderAfterNavermapsLoaded ncpClientId={ENV.NAVER.MAP.CLIENT_ID}>
            <App />
        </RenderAfterNavermapsLoaded>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
